/* eslint-disable react-hooks/exhaustive-deps */
import { LocalStorageKey } from '@/services/constants';
import { getLayout } from '@/services/services';
import { Center, Image, Loader, Stack } from '@mantine/core';
import Head from 'next/head';
import Router from 'next/router';
import path from 'path';
import { useEffect, useState } from 'react';
export default function Index() {
  const mappingLanguage = {
    'en-US': 'en',
    'vi-VN': 'vi',
  }
  const [config, setConfig] = useState({
    favicon: '',
    logo: '',
    primaryColor: '',
    titleHead: 'Ehiring Portal'
  });

  useEffect(() => {
    const { pathname } = Router;
    const oldTenant: string | null = localStorage.getItem(
      LocalStorageKey.TENANT
    );
    const hostNameUrl = window.location.host;
    getLayout(hostNameUrl).then((layoutFromApi) => {
      console.log("layoutFromApi", layoutFromApi);
      if (layoutFromApi) {
        const root = document.documentElement;

        const newTenant = layoutFromApi.tenant;
        if (
          newTenant.length > 0 &&
          ((oldTenant && newTenant !== oldTenant) || oldTenant === null)
        ) {
          localStorage.setItem(LocalStorageKey.TENANT, newTenant);
          localStorage.setItem(newTenant, hostNameUrl);
        }

        const layout = layoutFromApi.layout;
        const tempConfig = {
          ...layout.config,
          titleHead: layout.config.titleHead || 'Ehiring Portal'
        };
        const tempPage = layout.headers.find((e: any) => e.sort === 1);
        setConfig(tempConfig);
        root.style.setProperty('--primaryColor', tempConfig.primaryColor);
        root.style.setProperty('--secondaryColor', tempConfig.secondaryColor);
        const redirectTo = () => {
          if (tempConfig.defaultLanguage) {
            Router.push(`/${mappingLanguage[tempConfig.defaultLanguage as keyof typeof mappingLanguage]}${tempConfig.defaultHref}`);
          } else {
            Router.push(tempConfig.defaultHref);
          }
        };
        setTimeout(redirectTo, 300);
      } else {
        setTimeout(() => {
          Router.push('/home');
        }, 300);
      }
    });
  }, []);
  return (
    <>
      <Head>
        <title>{config.titleHead}</title>
        <meta name='description' content='Build your web' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link rel='icon' href={config.favicon} />
      </Head>
      {/* <main>
            <Carousel />
            </main> */}
      <Center style={{ height: '100vh' }}>
        <Stack>
          {config.logo !== '' ? (
            <Center>
              <Image src={config.logo} alt='' style={{ maxWidth: 500 }} />
            </Center>
          ) : (
            <></>
          )}

          <Center>
            <Loader
              variant='dots'
              color={config ? config.primaryColor : 'blue'}
            />
          </Center>
        </Stack>
      </Center>
    </>
  );
}
